@import 'Styles/Base/includes';

/**
*
* ButtonMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ButtonMenu {
    $root: &;

    position: relative;

    height: 48px;
    color: inherit;
    border: none;
    border-radius: 0;
    pointer-events: auto;
    cursor: pointer;

    &--Active {
        border-bottom: 1px solid rgba(white, .3);
    }

    &--Mobile {
        z-index: get-default($z-index, MenuMobile__Toggle);
    }

    &--Page {
        color: $text-color;

        @include media(md) {
            display: none !important;
        }
    }

    &__Container {
        display: flex;

        #{$root}--Mobile & {
            display: inline-flex;
        }
    }

    &:focus {
        outline: 0;
    }

    &__Icon {
        display: block;
        position: relative;
        width: 20px;
        height: 16px;

        span {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            transition: all .3s ease-in-out;

            &:first-child {

                #{$root}--Active & {
                    transform: rotate(45deg);
                    transform-origin: left;
                    width: 100%;
                }
            }

            &:nth-child(2) {

                #{$root}--Active & {
                    opacity: 0;
                }
            }

            &:last-child {
                bottom: 0;

                #{$root}--Active & {
                    transform: rotate(-45deg);
                    transform-origin: left;
                    width: 100%;
                }
            }
        }
    }
}
