@import 'Styles/Dansskolanentre/includes';

@import './Header.Base.module.scss';

.Header {
    $root: &;

    border-bottom: 1px solid $grey-20;

    &__Top {
        display: none;
    }

    &__Content {
        padding-top: 0;
        align-items: center;
    }

    &__Back {
    }

    &__Main {
        height: 80px;

        @include media(md) {
            height: 88px;
            padding-top: 7px;
            align-items: flex-start;
        }
    }

    &__Service {
        display: none;
    }

    &__ButtonLink {
        display: none;
    }

    &__Search {
        display: none;
    }

    &__Backdrop {
        display: none;
    }
}