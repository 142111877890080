@import 'Styles/Base/includes';

/**
*
* SearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SearchInput {
    $root: &;

    &__Container {
        display: flex;
    }

    &__Field {
        flex: 1 1 auto;
        position: relative;
        margin-right: 20px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            width: 14px;
            height: 14px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            pointer-events: none;

            @include media(md) {
                left: 28px;
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity .3s ease-in-out;
        }

        &:focus-within {
            &::after {
                opacity: 1;
            }
        }
    }

    &__Input {
        width: 100%;
        height: 50px;
        padding: 14px 20px 14px 30px;
        font-size: 1.6rem;
        border: none;
        border-radius: 0;
        background: transparent;
        box-shadow: none;

        @include media(md) {
            font-size: 1.8rem;
        }

        &::-webkit-search-decoration {
            display: none;
        }
    }

    &__Submit {
        flex: 0 0 auto;
        outline: none;
    }
}
