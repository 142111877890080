@import 'Styles/Pahlmans/includes';

@import './Header.Base.module.scss';

.Header {
    $root: &;

    box-shadow: 0 2px 6px 0 rgba(black, .1);

    &__Container {
        position: relative;
        background: white;
        z-index: z(Header__Container);
    }

    &__Content {
        padding-top: 17px;
    }

    &__Service {
        display: none;
    }

    &__Main {
        justify-content: space-between;
        z-index: z(Header__Content);

        :global(.MenuMobileActive) & {
            border-color: palette(black, 8);
        }

        @include media(md) {
            #{$root}--Empty & {
                justify-content: center;
                height: 72px;
            }
        }
    }

    &__Logo {
        width: 200px;

        img {
            width: 100%;
            height: auto;
        }

        // Fix overlap of logo and back button in 320px
        #{$root}--Application & {
            @media (max-width: 360px) {
                margin-left: auto;
            }
        }
    }

    &__ButtonLink {
        color: palette(black, 45);
        font-weight: $medium;
        border-color: palette(black, 15);

        &:hover {
            color: black;
            border-color: black;
        }
    }
}
