@import 'Styles/Medborgarskolan/includes';

/**
*
* SearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SearchInput.Base.module.scss';

.SearchInput {
    $root: &;

    background: white;
    border-radius: 100px;
    padding: 4px;

    &__Container {
        align-items: center;
    }

    &__Field {
        margin-right: 6px;
    }

    &__Input {
        width: 100%;
        margin: 0 0 0 2px;
        color: $text-color;
        font-family: $font-base;
        background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/search--blue.svg');
        background-position: center left 14px;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        padding: 13px 20px 14px 48px;
        border-radius: 100px;

        @include media(sm) {
            padding-left: 54px;
            background-position: center left 18px;
        }

        &::placeholder {
            color: $text-color;
        }

        &:focus::placeholder {
            color: $placeholder;
        }

        &:focus-visible {
            outline: solid $purple;
            margin: 0 3px;
        }
    }

    &__Submit {
        @include u-button(primary);

        width: 56px !important;
        height: 56px !important;
        padding: 16px;
        border-radius: 100px;

        @include media(sm) {
            width: auto !important;
        }

        &:focus {
            background: $blue-90;
        }

        &::after {
            content: '';
            display: inline-block;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/search--white.svg') no-repeat center center;
            width: 24px;
            height: 24px;
            margin: -4px -1px;

            @include media(sm) {
                margin-left: 8px;
            }
        }
    }

    &__SubmitText {
        display: none;

        @include media(sm) {
            display: inline-block;
        }
    }
}
