@import 'Styles/Medborgarskolan/includes';

@import './Header.Base.module.scss';

.Header {
    $root: &;

    background: transparent;
    box-shadow: 0 0 0 1px $grey-20;

    &--Minimal {
        position: relative;
        left: auto;
        transform: none;
        box-shadow: none;
    }

    &__Container {
        position: relative;
        background: white;
        z-index: z(Header__Container);
    }

    &__Content {
        min-height: 64px;
        padding-top: 20px;

        @include media(md) {
            min-height: 70px;
        }

        #{$root}--Application & {
            padding-top: 0;
            align-items: center;
        }
    }

    &__Back {
        color: $blue;
        font-weight: $normal;
        opacity: 1;

        &::before {
            width: 16px;
            height: 16px;
            margin: -2px 4px -2px -5px;
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/chevron--blue.svg');
            transform: rotate(180deg);
        }

        &::after {
            left: 0;
            right: 0;
            bottom: -2px;
            width: auto;
            height: 2px;
            background-color: $blue;
        }

        &:hover {
            opacity: 1;
        }

        &:hover::after {
            opacity: 1;
        }
    }

    &__Service {
        display: none;
        overflow: hidden;

        @include media(md) {
            display: block;
            border: none;
        }
    }

    &__Top {
        display: none;
    }

    &__Main {
        height: 64px;
        border: none;
        background-color: white;
        z-index: z(Header__Content);

        @include media(md) {
            justify-content: center;
            height: 64px;
        }

        @include media(lg) {
            height: 70px;
        }

        #{$root}--Minimal & {
            background: transparent;

            @include media(md) {
                height: 70px;
                background-color: white;
            }
        }
    }

    &__Logo {
        width: 108px;
        height: 31px;

        @include media(md) {
            align-self: flex-start;
            width: 120px;
            height: 35px;
        }

        @include media(lg) {
            width: 150px;
            height: 43px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;

            * {
                fill: $blue;
            }
        }

        #{$root}--Minimal & {
            margin-left: 0;

            @include media(md) {
                align-self: center;
            }
        }

        #{$root}--Application & {
            @include media(md) {
                align-self: center;
            }
        }
    }

    &__ButtonLink {
        display: none;
    }

    &__Search {
        display: none;

        @include media(md) {
            display: block;
        }
    }

    &__Backdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(black, .5);
        z-index: z(Header__Backdrop);

        @include media(md) {
            display: block;
        }
    }
}
