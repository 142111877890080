@import 'Styles/Medborgarskolan/includes';

@import 'MenuMobile.Base.module.scss';

.MenuMobile {
    $root: &;

    position: static;
    color: white;

    &__Toggle {
        z-index: z(Header__Content) + 1;

        #{$root}--Active & {
            bottom: -1px;
        }
    }

    &__Container {
        top: 64px;
        height: calc(100vh - 64px);
        border-top: 1px solid $grey-20;
        background: white;
    }

    &__Content {
        padding-top: 24px;
    }

    &__List {
        margin-bottom: 24px;
    }

    &__Link {
        color: $text-color;
        padding: 10px 20px;
        font-size: 1.4rem;

        &--Primary {
            color: $blue;
            font-size: 2.2rem;
            font-weight: $bold;
            font-family: $font-heading;
        }

        &--Secondary {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &--Search {
            &::before {
                content: '';
                display: inline-block;
                position: relative;
                top: 0px;
                width: 16px;
                height: 16px;
                margin-right: 6px;
                background: url('../#{$basepath}img/bg-icons/medborgarskolan/new/search--blue.svg') no-repeat center center;
                background-size: contain;
            }
        }
    }

    &__Search {
        display: block;
        width: 40px;
        height: 64px;
        padding: 12px 0;

        height: 40px;
        padding: 0;
        bottom: 12px;
        transform: none;
    }

    &__SearchIcon {
        width: 40px;
        height: 40px;
        background: $blue-20;

        &::after {
            border-color: $blue;
        }

        &::before {
            width: 24px;
            height: 24px;
            background-image: url('../#{$basepath}img/bg-icons/medborgarskolan/new/search--blue.svg');
        }
    }

    &__ButtonLink {
        display: none;
    }
}
