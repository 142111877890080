@import 'Styles/Pahlmans/includes';

@import 'Menu.Base.module.scss';

.Menu {
    $root: &;

    &--Primary {
        text-transform: uppercase;
    }

    &__Link {
        font-size: 1.8rem;
        font-weight: $medium;
        color: $heading-color;

        &:after {
            height: 1px;
            background-color: black;
        }

        &:hover:after {
            visibility: visible;
            opacity: 1;

            @media(hover: none) {
                visibility: visible;
                opacity: 0;
            }
        }

        #{$root}--Primary & {
            font-family: $font-heading;
            text-transform: none;
        }

        #{$root}--Secondary & {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: $bold;
        }

        #{$root}__Item--Search & {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: $menu-hover;
            transition: background-color .3s ease-in-out;

            &:hover {
                background: $menu-icon-hover;
            }

            span {
                @extend %sr-only;
            }

            &::after {
                display: none;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 18px;
                height: 18px;
                margin: auto;
                background-image: url('../#{$basepath}img/bg-icons/pahlmans/search--blue.svg');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                pointer-events: none;
            }
        }
    }
}
