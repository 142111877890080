@import 'Styles/Base/includes';

.MenuMobile {
    $root: &;

    position: relative;

    @include media(md) {
        display: none;
    }

    &__Toggle {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 0;
        padding: 0;
        color: inherit;
        font-size: 1.2rem;
        font-family: $font-base;
        letter-spacing: 0.1rem;
        border: none;
        border-radius: 0;
        pointer-events: none;
        z-index: get-default($z-index, MenuMobile__Toggle);

        #{$root}--Active & {
            border-bottom: 1px solid rgba(white,0.3);
        }

        &:focus {
            outline: 0;
        }
    }

    &__Animate {
        opacity: 0;

        #{$root}--Active & {
            opacity: 1;
        }
    }

    &__Container {
        position: absolute;
        padding-bottom: 60px;
        top: 90px;
        width: 100%;
        height: calc(100vh - 90px);
        pointer-events: none;
        overflow-y: auto;
        z-index: get-default($z-index, MenuMobile__Content);

        #{$root}--Active & {
            pointer-events: auto;
        }
    }

    &__Content {
        width: 100%;
        display: block;
        padding-top: 80px;
        padding-bottom: 20px;
    }

    &__List {
        margin-bottom: 20px;
    }

    &__Link {
        display: block;
        padding: 10px 20px;
        font-size: 2.3rem;
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        &--Secondary {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 1.8rem;
            font-weight: $normal;
            text-transform: none;
        }
    }

    &__Search {
        display: none;
        position: absolute;
        bottom: 50%;
        right: 56px;
        width: 36px;
        height: 46px;
        padding: 6px 0;
        transform: translateY(-50%);
        z-index: get-default($z-index, MenuMobile__Toggle);
        pointer-events: auto;

        @include media(sm) {
            right: 66px;
        }
    }

    &__SearchIcon {
        display: block;
        width: 36px;
        height: 36px;
        margin: auto;
        border-radius: 50%;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border: 1px solid black;
            border-radius: 50%;
            background: none;
            opacity: 0;
            transition: opacity .3s ease-in-out;

            #{$root}__Search:hover & {
                opacity: 1;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 18px;
            height: 18px;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            pointer-events: none;
        }
    }

    &__ButtonLink {
        display: none;
        padding: 16px 30px 18px;
        font-size: 1.5rem;
        line-height: 1;
        text-transform: uppercase;
        border: 1px solid black;
        transition: color .2s, border-color .2s;

        @include media(md) {
            display: block;
        }

        #{$root}--Active & {
            display: block;
            padding: 20px;
            margin: 0 20px 50px;
            text-align: center;
        }
    }
}
