@import 'Styles/Medborgarskolan/includes';

/**
*
* ButtonMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ButtonMenu.Base.module.scss';

.ButtonMenu {
    $root: &;

    // display: flex;
    // align-items: center;
    width: 100%;
    padding: 0;
    font-size: 1.2rem;
    font-weight: $bold;
    font-family: $font-base;
    letter-spacing: 10px;
    text-transform: uppercase;

    &--Mobile {
        position: absolute;
        bottom: 50%;
        right: 10px;
        padding: 0;
        width: auto;
        height: 46px;
        background: transparent;
        transform: translateY(-50%);

        height: 40px;
        bottom: 12px;
        transform: none;

        @include media(sm) {
            right: 20px;
        }
    }

    &--Page {
        height: 52px;
        padding-left: 18px;
        border-bottom: 1px solid transparent;
        border-radius: 8px;
        background-color: $blue-20;

        &#{$root}--Active {
            background-color: $blue-10;
        }
    }

    &__Container {
        align-items: center;

        #{$root}--Mobile & {
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $blue-20;
        }
    }

    &__Text {
        display: none;
    }

    &__TopItemTitle {
        display: none;
    }

    &__Icon {
        #{$root}--Page & {
            margin-right: 14px;
            width: 14px;
            height: 12px;
            color: $blue;
        }

        span {
            border-radius: 1px;
            background-color: currentColor;

            #{$root}--Mobile & {
                background-color: $blue;
            }

            &:nth-child(2) {
                // top: calc((100% / 2) - 1px);
            }

            &:first-child {
                #{$root}--Active & {
                    left: 3px;
                }
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);

                #{$root}--Active & {
                    opacity: 0;
                }
            }

            &:last-child {
                #{$root}--Active & {
                    left: 3px;
                }
            }
        }
    }
}
