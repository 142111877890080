@import 'Styles/Pahlmans/includes';

/**
*
* TopMenuSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'TopMenuSelect.Base.module.scss';

.TopMenuSelect {
    $root: &;

    left: 0;
    right: 0;
    padding: 20px;
    border: none;
    background: $primary-color;

    &::after { // Arrow
        top: 25px;
        left: 20px;
        border-color: white;
    }

    &__List {
        padding: 0 0 0 20px;
        color: white;
    }
}
