@import 'Styles/Kulturama/includes';

@import 'Menu.Base.module.scss';

.Menu {
    $root: &;

    &--Primary {
        text-transform: uppercase;
    }

    &__Link {
        font-weight: $medium;
        color: palette(black, 80);

        #{$root}--Primary & {
            letter-spacing: 0.09rem;
        }

        #{$root}--IsMinimal & {
            color: palette(white);
        }

        &:after {
            background-color: palette(black, 80);

            #{$root}--IsMinimal & {
                background-color: palette(white);
            }
        }

        #{$root}--Secondary & {
            color: palette(black, 50);
        }

        #{$root}--IsMinimal & {
            color: palette(white);
        }
    }
}
