@import 'Styles/Tillskararakademin/includes';

@import 'Menu.Base.module.scss';

.Menu {
    $root: &;

    &--Primary {
        text-transform: uppercase;
    }

    &--Secondary {
        height: 80px;
        padding-left: 8px;
        padding-right: 8px;
    }

    &__Link {
        font-family: $font-heading;
        font-size: 2rem;
        text-transform: none;
        font-weight: $medium;
        color: palette(black);

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: palette(black, 80);
            transition: opacity .3s;
        }

        #{$root}--Primary & {
            letter-spacing: 0.09rem;
        }

        #{$root}--Secondary & {
            color: palette(black, 80);
            font-family: $font-base;
        }

        #{$root}--Application & {
            font-family: $font-base;
            opacity: 1;

            &:hover {
                opacity: 1;

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
