@import 'Styles/Base/includes';

/**
*
* TopMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.TopMenu {
    $root: &;

    display: none;

    @include media(md) {
        display: block;
    }

    &__List {
        @include u-wrap(site);
        display: flex;
    }

    &__Item {
        margin-right: 20px;
    }

    &__Link {
        display: block;
        padding: 7px 0;

        #{$root}__Item--Current & {
            text-decoration: underline;
        }
    }
}
