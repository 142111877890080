@import 'Styles/Kulturama/includes';

/**
*
* ButtonMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ButtonMenu.Base.module.scss';

.ButtonMenu {
    $root: &;

    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: $bold;
    font-family: $font-base;
    background-color: white;

    &--Minimal {
        background-color: transparent;
        color: palette(white);
    }

    :global(.MenuMobileActive) & {
        height: 58px;
        color: palette(black);
    }

    &--Active {
        color: palette(pink);
    }

    &--Mobile {
        position: absolute;
        // top: 50%;
        bottom: 50%;
        right: 10px;
        transform: translateY(-50%);

        @include media(sm) {
            right: 20px;
        }
    }

    &--Page {
        height: 60px;
        width: 100%;
        padding: 10px 0;
        font-size: 1.6rem;
        font-weight: $medium;
        border-bottom: 3px solid palette(pink);

        :global(.MenuPageMobileActive) & {
            height: 60px;
            padding-left: 12px;
            padding-right: 12px;

            @include media(sm) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    &__Container {
        align-items: end;
    }

    &__Text {
        order: 1;
        margin-right: 10px;
        line-height: 1;

        #{$root}--Page & {
            display: none;
        }
    }

    &__TopItemTitle {
        font-size: 1.6rem;

        #{$root}--Page & {
            padding-left: 40px;
        }
    }

    &__Icon {
        order: 2;

        #{$root}--Page & {
            width: 18px;
            height: 12px;
            color: palette(pink);
            order: initial;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        #{$root}--Active#{$root}--Page & {
            height: 15px;
        }

        span {
            background-color: currentColor;

            &:nth-child(2) {
                top: calc((100% / 2) - 1px);
            }
        }
    }
}
