@import 'Styles/Kulturama/includes';

@import 'MenuMobile.Base.module.scss';

.MenuMobile {
    $root: &;

    position: static;

    &__Toggle {
        text-transform: uppercase;
    }

    &__Container {
        background: palette(white);
    }

    &__Content {
        :global(.PageHomeKulturama) & {
            padding-top: 20px;
        }
    }

    &__Link {
        font-weight: $bold;
        text-transform: uppercase;

        &--Secondary {
            font-weight: $normal;
        }
    }

    &__ButtonLink {
        color: palette(black, 45);
        font-weight: $medium;
        text-transform: uppercase;
        border-color: palette(black, 10);

        &:hover {
            color: palette(black);
            border-color: palette(black);
        }
    }
}
