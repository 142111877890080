@import 'Styles/Kulturama/includes';

@import './Header.Base.module.scss';

.Header {
    $root: &;

    box-shadow: 0 2px 6px 0 rgba(black, .1);

    &--Minimal {
        box-shadow: none;
    }

    &__Main {
        :global(.MenuMobileActive) & {
            border-color: palette(black, 10);
        }
    }

    &__Service {
        display: none;
    }

    &__ButtonLink {
        color: palette(black, 45);
        font-weight: $medium;
        text-transform: uppercase;
        border-color: palette(black, 10);

        &:hover {
            color: palette(black);
            border-color: palette(black);
        }
    }
}
