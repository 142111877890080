@import 'Styles/Pahlmans/includes';

/**
*
* Search
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Search.Base.module.scss';

.HeaderSearch {
    $root: &;

    border-color: palette(black, 5);
    background: palette(black, 5);

    &__Container {
        @include u-wrap(article);
    }
}
