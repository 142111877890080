@import 'Styles/Pahlmans/includes';

@import 'MenuMobile.Base.module.scss';

.MenuMobile {
    $root: &;

    position: static;

    &__Container {
        background: white;
    }

    &__Link {
        font-size: 3.2rem;
        font-family: $font-heading;
        color: $heading-color;

        &--Secondary {
            font-family: $font-base;
            font-size: 1.4rem;
            font-weight: $bold;
            text-transform: uppercase;
        }
    }

    &__Search {
        display: block;
    }

    &__SearchIcon {
        background: $menu-hover;

        &::after {
            border-color: $primary-color;
        }

        &::before {
            background-image: url('../#{$basepath}img/bg-icons/pahlmans/search--blue.svg');
        }
    }

    &__ButtonLink {
        color: palette(black, 45);
        font-weight: $medium;
        border-color: palette(black, 15);

        &:hover {
            color: black;
            border-color: black;
        }
    }
}
