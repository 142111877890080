@import 'Styles/Pahlmans/includes';

/**
*
* ButtonMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ButtonMenu.Base.module.scss';

.ButtonMenu {
    $root: &;

    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-weight: $black;
    font-family: $font-heading;
    background-color: transparent;

    :global(.MenuPageMobileActive) & {
        height: 58px;
        background-color: white;
    }

    &--Mobile {
        position: absolute;
        // top: 50%;
        bottom: 50%;
        right: 10px;
        padding: 0;
        height: 46px;
        transform: translateY(-50%);

        @include media(sm) {
            right: 20px;
        }
    }

    &--Page {
        width: 100%;
        padding: 0 0 10px;
        font-size: 1.6rem;
        font-weight: $normal;
        border-bottom: 3px solid $menu-active;

        :global(.MenuPageMobileActive) & {
            padding: 10px 0;
        }
    }

    &__Container {
        align-items: center;

        #{$root}--Mobile & {
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: $menu-hover;
        }
    }

    &__Text {
        display: none;
    }

    &__TopItemTitle {
        color: $menu-active;
        font-size: 1.6rem;
        font-weight: $medium;
        text-transform: uppercase;
    }

    &__Icon {
        order: 2;

        #{$root}--Page & {
            margin-right: 15px;
            order: initial;
        }

        #{$root}--Active#{$root}--Page & {
            margin-left: 20px;
        }

        span {
            background-color: $primary-color;

            &:first-child {

                #{$root}--Active & {
                    left: 3px;
                }
            }

            &:nth-child(2) {
                top: 50%;
                transform: translateY(-50%);

                #{$root}--Active & {
                    opacity: 0;
                }
            }

            &:last-child {

                #{$root}--Active & {
                    left: 3px;
                }
            }
        }
    }
}
