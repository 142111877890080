@import 'Styles/Pahlmans/includes';

/**
*
* SearchInput
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'SearchInput.Base.module.scss';

.SearchInput {
    $root: &;

    &__Field {
        margin-right: 12px;

        @include media(md) {
            margin-right: 16px;
        }

        &::before {
            width: 18px;
            height: 18px;
            background-image: url('#{$basepath}img/bg-icons/pahlmans/search--blue.svg');
        }

        &::after {
            top: 0;
            border: 2px solid $primary-color;
            border-radius: 4px;
        }
    }

    &__Input {
        height: 72px;
        padding: 26px 20px 26px 50px;
        color: palette(black, 86);
        font-size: 1.6rem;
        font-weight: normal;
        font-family: $font-base;
        border: 1px solid palette(black, 15);
        border-radius: 4px;
        background: white;

        &::placeholder {
            color: palette(black, 72);
        }

        @include media(md) {
            height: 80px;
            padding: 26px 26px 26px 64px;
            font-size: 1.8rem;
        }
    }

    &__Submit {
        @include u-button(primary);

        height: 72px;
        min-width: 72px;

        @include media(md) {
            height: 80px;
            min-width: 80px;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 18px;
            height: 18px;
            margin: auto;
            background: url('#{$basepath}img/bg-icons/base/search--white.svg') no-repeat center center;
            background-size: contain;
            z-index: 1;
        }
    }

    &__SubmitText {
        #{$root} & {
            @extend %sr-only;

            position: absolute !important;
        }
    }
}
