@import 'Styles/Base/includes';

/**
*
* Search
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeaderSearch {
    $root: &;

    border-top: 1px solid;

    &__Container {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
