@import 'Styles/Dansskolanentre/includes';

@import 'MenuMobile.Base.module.scss';

.MenuMobile {
    $root: &;

    &__Toggle {
        text-transform: uppercase;
    }

    &__Container {
        top: 0;
        height: 100vh;
        background: white;
    }

    &__Content {
        padding-top: 32px;
    }

    &__Link {
        font-size: 2.4rem;
        font-weight: $bold;

        &--Secondary {
            font-size: 1.6rem;
            font-weight: $normal;
            text-transform: uppercase;
        }
    }

    &__ButtonLink {
        display: none;
    }
}
