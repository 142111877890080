@import 'Styles/Kulturama/includes';

/**
*
* TopMenu
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'TopMenu.Base.module.scss';

.TopMenu {
    $root: &;

    background: palette(black);

    :globa(.PageHomeKulturama) & {
        display: none;
    }

    &__Item {
        font-weight: $medium;
    }

    &__Link {
        color: palette(white);
    }
}
