@import 'Styles/Base/includes';

/**
*
* TopMenuSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.TopMenuSelect {
    $root: &;

    display: none;
    position: absolute;
    left: 0;
    top: 90px;
    border-bottom: 3px solid black;
    left: 12px;
    right: 12px;
    background: white;

    @include media(md) {
        display: none !important;
    }

    :global(.MenuMobileActive) & {
        display: block;
    }

    &::after { // Arrow
        content: '';
        position: absolute;
        top: 26px;
        left: 10px;
        width: 6px;
        height: 6px;
        background: transparent;
        border-top: 2px solid black;
        border-right: 2px solid black;
        transform: rotate(135deg);
    }

    &__List {
        width: 100%;
        padding: 20px 12px 20px 40px;
        color: $text-color;
        font-family: $font-base;
        font-size: 1.6rem;
        border: none;
        background: transparent;
        -webkit-appearance: none;

        &:focus {
            outline: none;
        }
    }
}
