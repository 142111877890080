@import 'Styles/Medborgarskolan/includes';

@import 'Menu.Base.module.scss';

.Menu {
    $root: &;

    &--Primary {
        margin-right: 0;
        margin-left: auto;
        margin-top: 14px;
        padding-left: 16px;
        align-self: flex-end;

        @include media(lg) {
            margin-top: 20px;
        }
    }

    &--Secondary {
        @include u-wrap(site);

        display: flex;

        &#{$root}--Main {
            display: none;
        }
    }

    &--Application {
        margin-right: 0;
    }

    &__List {
        #{$root}--Secondary & {
            position: relative;
            padding-left: 60px;
            padding-right: 6px;
            margin-left: auto;
            justify-content: flex-end;
            background: url('../#{$basepath}img/bg-icons/medborgarskolan/new/menu-secondary-bg--blue-20.svg') no-repeat left top;
            background-size: auto 100%;

            // Covering background if menu too wide and towards viewport end
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 60px;
                width: calc(100% + 12px - 60px);
                height: 100%;
                background: $blue-20;
                z-index: -1;

                @include media(sm) {
                    width: calc(100% + 20px - 60px);
                }

                @include media(lg) {
                    width: calc(100% + 30px - 60px);
                }

                @include media(xl) {
                    width: calc(100vw - #{map-get($maxwidths, site)} + 100% + 120px - 60px);
                }
            }
        }
    }

    &__Item {
        height: 50px;
        display: inline-block;
        padding-left: 0;
        padding-right: 0;
        text-align: center;

        #{$root}--Secondary & {
            height: 40px;
            padding: 6px 0;
        }

        #{$root}--IsMinimal & {
            height: 50px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__Link {
        height: 50px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 1.4rem;
        font-weight: $normal;
        color: $text-color;

        &::after {
            height: 5px;
            background-color: $blue;
        }

        #{$root}--Primary & {
            @include media(lg) {
                font-size: 1.5rem;
            }

            @include media(xl) {
                font-size: 1.6rem;
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        #{$root}--Secondary & {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 1.2rem;
            height: 28px;
            line-height: 28px;

            @include media(lg) {
                padding-left: 12px;
                padding-right: 12px;
            }

            &::after {
                left: 10px;
                right: 10px;
                bottom: 2px;
                width: auto;
                height: 2px;
            }
        }

        #{$root}--Application & {
            color: $blue;
            font-size: 1.6rem;
            opacity: 1;

            &::after {
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: 2px;
            }

            &:hover {
                opacity: 1;
            }

            &:hover::after {
                opacity: 1;
            }
        }

        #{$root}--IsMinimal & {
            height: 50px;
        }

        #{$root}__Item--Current & {
            &::after {
                opacity: 1;
            }
        }

        #{$root}__Item--Search & {
            &::before {
                content: '';
                display: inline-block;
                position: relative;
                top: 4px;
                width: 16px;
                height: 16px;
                margin-right: 6px;
                background: url('../#{$basepath}img/bg-icons/medborgarskolan/new/search--blue.svg') no-repeat center center;
                background-size: contain;
            }
        }
    }

    &__Loader {
        left: 10px;
    }
}
