@mixin u-loader($size: 50px, $color: $blue, $bgColor: rgba($color, 0.2)) {
    $lineWidth: $size / 10;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    margin: auto;
    width: $size;
    height: $size;
    border-radius: 50%;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        display: block;
        width: calc(100% - #{($lineWidth * 2)});
        height: calc(100% - #{($lineWidth * 2)});
        border-radius: 50%;
    }

    &::before {
        position: relative;
        border: $lineWidth solid $bgColor;
        border-left-color: $color;
        transform: translateZ(0);

        :global {
            animation: spin 1.1s infinite linear;
        }
    }
}