@import 'Styles/Tillskararakademin/includes';

/**
*
* TopMenuSelect
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'TopMenuSelect.Base.module.scss';

.TopMenuSelect {
    $root: &;

    width: auto;
    border-color: palette(green);
    background: palette(white);

    &::after { // Arrow
        border-color: palette(black, 80);
    }

    &__List {
        font-weight: $medium;
    }
}
